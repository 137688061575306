x<template>
  <div class="labelcreat">
    <el-card class="card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item>
                <el-input
                  v-model="labelForm.labelName"
                  size="medium"
                  placeholder="标签名称"
                  clearable
                  @keyup.enter.native="searchLabel"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button size="medium" @click="searchLabel">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="medium" icon="el-icon-refresh" @click="refreshButton">刷新</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="10" style="text-align: right; float: right">
              <el-form-item>
                <el-button @click="syncLabel" icon="el-icon-refresh-left" size="medium" v-if="isshow">标签同步</el-button>
              </el-form-item>
              <el-form-item>
                <el-button
                  :type="tye"
                  @click="createDialogVisible = true"
                  size="medium"
                  >{{ btnTitle }}</el-button
                >
              </el-form-item>
              
            </el-col>
          </el-row>
        </el-form>
        <template v-if="percentage !== 0">
          <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage" status="success"></el-progress>
        </template>
      </div>
    </el-card>

    <el-tabs
      style="margin-top: 10px"
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane v-if="roleLevel === '商盟' || roleLevel === '平台'" label="商盟标签" name="first">
        <el-card
          class="box-card"
          shadow="hover"
          v-for="(item, index) in first"
          :key="index"
          style="margin-right: 20px; margin-bottom: 15px"
        >
          <div slot="header" class="clearfix">
            <span>{{ item.aliLabelName }}</span>
            <el-button
              type="text"
              style="float: right"
              @click="
                editLabel(
                  item.aliLabelId,
                  item.aliLabelName,
                  item.aliLabelNotes,
                  'S'
                )
              "
              ><i class="el-icon-edit-outline" style="color: #ffffff"></i
            ></el-button>
          </div>
          <div class="text item">
            <div class="one_text">
              <span>会员数量：{{ item.cout }}</span>
              &nbsp;
              <!-- <span
                ><a
                  type="button"
                  href="#"
                  @click="getVipUsers(item.aliLabelId, item.aliLabelName, 'S')"
                  >查看详情</a
                ></span
              > -->
              <el-button
                type="text"
                icon="el-icon-plus"
                @click="sendAdd(item.merLabelId, item.merLabelName, 'M')"
                >会员详情</el-button
              >
            </div>
            <div class="one_text">
              <span>创建时间：</span>
              <span>{{ item.openDatetime }}</span>
            </div>
            <div class="one_text">
              <span>更新时间：</span>
              <span>{{ item.openUpdateTime }}</span>
            </div>
            <div
              class="one_text"
              style="border: 0.5px solid grey; width: 270px"
            ></div>
            <div
              class="one_text"
              style="text-align: center; justify-content: center"
            >
              <el-button
                type="text"
                icon="el-icon-position"
                @click="
                  sendGroup(
                    item.aliLabelId,
                    item.aliLabelLevel,
                    item.aliLabelName,
                    'S'
                  )
                "
                >消息群发</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                icon="el-icon-delete"
                @click="
                  deleteLabel(
                    item.aliLabelId,
                    'S',
                    item.aliWxId,
                    item.aliLabelName
                  )
                "
                >标签删除</el-button
              >
            </div>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="商户标签" name="second">
        <el-card
          class="box-card"
          shadow="hover"
          v-for="(item, index) in second"
          :key="index"
          style="margin-right: 20px; margin-bottom: 15px"
        >
          <div slot="header" class="clearfix">
            <span>{{ item.merLabelName }}</span>
            <el-button
              type="text"
              style="float: right;color: #ffffff"
              icon="el-icon-edit-outline"
              @click="
                editLabel(
                  item.merLabelId,
                  item.merLabelName,
                  item.merLabelNotes,
                  'M'
                )
              "
              ></el-button>
            <el-upload
              :show-file-list="false"
              :before-upload="beforeUpload"
              :disabled="importDataDisabled"
              style="float: right;color: #ffffff;margin-right:5px;" 
              :action="item.merLabelId"
              :http-request="exportData"
              multiple
            >
              <el-button
                :disabled="importDataDisabled"
                type="text"
                :icon="importDataBtnIcon"
                style="float: right;color: #000000;margin-right:5px;" 
              >
              </el-button>
            </el-upload>
          </div>
          <div class="text item">
            <div class="one_text">
              <span>会员数量：{{ item.cout }}</span>
              &nbsp;
              <el-button
                type="text"
                @click="detailAdd(item.merLabelId, item.merLabelName, 'M')"
                >查看详情</el-button
              >
            </div>
            <div class="one_text">
              <span>创建时间：</span>
              <span>{{ item.openDatetime }}</span>
            </div>
            <div class="one_text">
              <span>更新时间：</span>
              <span>{{ item.openUpdateTime }}</span>
            </div>
            <div
              class="one_text"
              style="border: 0.5px solid grey; width: 270px"
            ></div>
            <div
              class="one_text"
              style="text-align: center; justify-content: center"
            >
              <el-button
                v-if="
                  item.merLabelName !== '睡眠会员' &&
                  item.merLabelName !== '低频会员' &&
                  item.merLabelName !== '高频会员'
                "
                type="text"
                icon="el-icon-plus"
                @click="sendAdd(item.merLabelId, item.merLabelName, 'M')"
                >添加会员</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                icon="el-icon-position"
                @click="
                  sendGroup(
                    item.merLabelId,
                    item.merLabelLevel,
                    item.merLabelName,
                    'M'
                  )
                "
                >消息群发</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                v-if="
                  item.merLabelName !== '睡眠会员' &&
                  item.merLabelName !== '低频会员' &&
                  item.merLabelName !== '高频会员'
                "
                type="text"
                icon="el-icon-delete"
                @click="
                  deleteLabel(
                    item.merLabelId,
                    'M',
                    item.merWxId,
                    item.merLabelName
                  )
                "
                >标签删除</el-button
              >
            </div>
          </div>
        </el-card>
      </el-tab-pane>
    </el-tabs>

    <!-- 增加或修改标签对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="createDialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
      class="labelCreate"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item
          label="标签名称:"
          prop="labelName"
          label-width="100px"
          style="width: 100%"
        >
          <el-input
            v-model="editForm.labelName"
            placeholder="最多12个汉字"
            maxlength="12"
            auto-complete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="备注:"
          prop="labelNotes"
          label-width="100px"
          style="width: 100%"
        >
          <el-input
            v-model="editForm.labelNotes"
            auto-complete="off"
            width="60%"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="margin-left: 15px"
            type="primary"
            @click="submitForm('editForm')"
            >保 存</el-button
          >
          <el-button @click="resetForm('editForm')">关 闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--会员详情-->
    <el-dialog
      :title="labelName"
      :visible.sync="detailsDialog"
      width="70%"
      :before-close="detailsClose"
      :close-on-click-modal="false"
      center
    >
    <el-card class="card-contanier">
        <div class="card-content">
          <el-form :inline="true" @submit.native.prevent>
            <div v-show="nohigh" style="float: left">
              <el-form-item>
                <el-input
                  v-model="weldingForm.pvipName"
                  size="medium"
                  placeholder="会员姓名"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="weldingForm.pvipPhone"
                  size="medium"
                  placeholder="手机号"
                  clearable
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button @click="ghSearch" size="medium">搜索</el-button>
              </el-form-item>
            </div>
            <div style="float: right; margin-right: 20px">
              <el-form-item class="high_serch">
                <el-button
                  @click="showHighSerch"
                  :icon="nohigh ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                  type="text"
                  size="medium"
                  >高级搜索</el-button
                >
              </el-form-item>
            </div>
            <div v-show="!nohigh" style="float: left; width: 90%">
              <el-form-item label="手机号:">
                <el-input
                  v-model="weldingForm.pvipPhone"
                  size="medium"
                  placeholder="手机号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="会员姓名:">
                <el-input
                  v-model="weldingForm.pvipName"
                  size="medium"
                  placeholder="会员姓名"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="会员昵称:">
                <el-input
                  v-model="weldingForm.pvipNickname"
                  size="medium"
                  placeholder="会员昵称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="注册时间:">
                <el-date-picker
                  v-model="openTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="会员等级:">
                <el-select v-model="Level" placeholder="会员等级">
                  <el-option
                    v-for="item in LevelOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="会员卡号:">
                <el-input
                  v-model="weldingForm.pvipCards"
                  size="medium"
                  placeholder="会员卡号"
                  clearable
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="是否关注公众号:">
                <el-select v-model="pPublic" placeholder="是否关注公众号">
                  <el-option
                    v-for="item in pvipPublic"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item>
                <el-button @click="ghSearch" icon="el-icon-search" size="medium"
                  >搜索</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button @click="ghRefresh" icon="el-icon-refresh" size="medium"
                  >重置</el-button
                >
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-card>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        v-loading="addTableLoading"
        border
        style="width: 100%"
        stripe
        size="small"
        @select="selectDetailItem"
        @select-all="selectDetailAll"
        :row-style="{ height: '40px' }"
        :cell-style="{ padding: '1px 0' }"
      >
      <el-table-column prop="id" fixed align="center">
          <el-table-column type="selection" width="75" :selectable="selectable"></el-table-column>
          <!-- <template slot="header">
            <div>
              <el-checkbox v-model="checkedAll" @change="changeCheckAll" :disabled="addData.length === 0">所有</el-checkbox>
            </div>
          </template> -->
        </el-table-column>
      <el-table-column label="序号" width="50" align="center">
          <template slot-scope="scop">
              {{scop.$index+1}}
          </template>
      </el-table-column>
       
        <el-table-column
          prop="pvip_name"
          label="会员姓名"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_nickname"
          label="会员昵称"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_cards"
          label="会员卡号"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_phone"
          label="手机号"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_level"
          label="会员等级"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              size="small"
              v-if="scope.row.pvip_level === 0"
              >普通会员</el-tag
            >
            <el-tag
              type="success"
              size="small"
              v-else-if="scope.row.pvip_level === 1"
              >黄金会员</el-tag
            >
            <el-tag
              type="danger"
              size="small"
              v-else-if="scope.row.pvip_level === 2"
              >白金会员</el-tag
            >
          
          </template>
        </el-table-column>
        <el-table-column
          prop="pVipOpDms"
          label="注册时间"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="me_name"
          label="所属商户"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="handleSizexpChange"
          @current-change="handleCurrentxpChange"
          :current-page="currentxp"
          :page-sizes="[10]"
          :page-size="sizexp"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalxp"
        ></el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailsClose">关闭</el-button>
        <el-button type="primary" @click="onSubmit('D')"
          >重打</el-button
        >
        <el-button type="danger" @click="eliminate('D')"
          >剔除</el-button
        >
      </div>
    </el-dialog>
    <!--添加会员-->
    <el-dialog
      :title="labelName"
      :visible.sync="addDialog"
      width="70%"
      :before-close="addClose"
      :close-on-click-modal="false"
      center
    >
      <el-card class="card-contanier">
        <div class="card-content">
          <el-form :inline="true" @submit.native.prevent>
            <div v-show="nohigh" style="float: left">
              <el-form-item>
                <el-input
                  v-model="searchForm.pvipName"
                  size="medium"
                  placeholder="会员姓名"
                  clearable
                  @keyup.enter.native="merVipInfo(false)"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="searchForm.pvipPhone"
                  size="medium"
                  placeholder="手机号"
                  clearable
                  @keyup.enter.native="merVipInfo(false)"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button @click="merVipInfo(false)" size="medium">搜索</el-button>
              </el-form-item>
            </div>
            <div style="float: right; margin-right: 20px">
              <el-form-item class="high_serch">
                <el-button
                  @click="showHighSerch"
                  :icon="nohigh ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                  type="text"
                  size="medium"
                  >高级搜索</el-button
                >
              </el-form-item>
            </div>
            <div v-show="!nohigh" style="float: left; width: 90%">
              <el-form-item label="手机号:">
                <el-input
                  v-model="searchForm.pvipPhone"
                  size="medium"
                  placeholder="手机号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="会员姓名:">
                <el-input
                  v-model="searchForm.pvipName"
                  size="medium"
                  placeholder="会员姓名"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="会员昵称:">
                <el-input
                  v-model="searchForm.pvipNickname"
                  size="medium"
                  placeholder="会员昵称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="注册时间:">
                <el-date-picker
                  v-model="openTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="会员等级:">
                <el-select v-model="Level" placeholder="会员等级">
                  <el-option
                    v-for="item in LevelOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="会员卡号:">
                <el-input
                  v-model="searchForm.pvipCards"
                  size="medium"
                  placeholder="会员卡号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="search" icon="el-icon-search" size="medium"
                  >搜索</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button @click="refresh" icon="el-icon-refresh" size="medium"
                  >重置</el-button
                >
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-card>
      <div style="height: 15px;padding-left: 10px;margin-top: 20px;">
        已选择&nbsp;<span style="color: red">{{checkedAll ? total : checkList.length}}</span>&nbsp;项
      </div>
      <el-table
        ref="multipleTables"
        :data="addData"
        v-loading="addTableLoading"
        tooltip-effect="dark"
        border
        style="width: 100%"
        stripe
        size="small"
        :row-style="{ height: '10px' }"
        :cell-style="{ padding: '1px 0' }"
        @selection-change="handleSelectionChange"
        @select="selectItem"
        @select-all="selectAll"
        class="tjhy-dialog-table"
      >
        <el-table-column prop="id" fixed align="center">
          <el-table-column type="selection" width="75" :selectable="selectable"></el-table-column>
          <template slot="header">
            <div>
              <el-checkbox v-model="checkedAll" @change="changeCheckAll" :disabled="addData.length === 0">所有</el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="60" :index="indexMethod" align="center" />
       
        <el-table-column
          prop="pvip_name"
          label="会员姓名"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_nickname"
          label="会员昵称"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_cards"
          label="会员卡号"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_phone"
          label="手机号"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pvip_level"
          label="会员等级"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              size="small"
              v-if="scope.row.pvip_level === 0"
              >普通会员</el-tag
            >
            <el-tag
              type="success"
              size="small"
              v-else-if="scope.row.pvip_level === 1"
              >黄金会员</el-tag
            >
            <el-tag
              type="danger"
              size="small"
              v-else-if="scope.row.pvip_level === 2"
              >白金会员</el-tag
            >
            <!-- <div type="text"  @click="editHandler(scope.row)">
              <a style="color:black" href="#">{{scope.row.pvipLevel}}</a>
            </div> -->
          </template>
        </el-table-column>
        <!-- <el-table-column prop="pvip_balance" label="会员余额" show-overflow-tooltip align="center">
        </el-table-column> -->
        <el-table-column prop="pVipOpDms" label="注册时间" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="vcard_me_name" label="所属商户" show-overflow-tooltip align="center"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          style="margin-top: 20px;text-align: right;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="[10,20,50,100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addClose">关闭</el-button>
        <el-button type="primary" :disabled="choiceDisabled" @click="onSubmit('C')"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <!-- <infoLabelModel title="测试" v-if="openDialog" ref="infoLabelModel"/> -->
  </div>
</template>
<script>
import labelApi from "../../api/label";
import api from "../../api/solidifiaction";
import user from "../../api/user";
import pr from "../../api/private";
var sid = Math.random()*10000 // 在1-10000中生成随机数,可以改为时间戳，降低重复概率
export default {
  name: "labelcreat",
  data() {
    return {
      checkedAll: false,
      btnTitle: "新建商户标签",
      activeName: "second",
      tye: "primary",
      first: [],
      second: [],
      openDialog:false,
      tableData: [],
      addData: [],
      searchForm: {},
      weldingForm:{},
      labelForm: { labelName: "" },
      total: 10,
      size: 10,
      current: 1,
      totalxp: 10,
      sizexp: 10,
      currentxp: 1,
      addTableLoading: false,
      createDialogVisible: false, //创建标签的对话框
      detailsDialog: false, //会员详情对话框
      addDialog: false, //添加会员对话框
      choiceDisabled: true, //选择群发
      title: "添加商户标签",
      editForm: {},
      nohigh: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      editFormRules: {
        labelName: [
          {
            required: true,
            message: "输入标签名称,最多十二个汉字",
            trigger: "blur",
          },
        ],
      },
      LevelOptions:[{
        value: 0,
        label: '普通会员'
      }, {
        value: 1,
        label: '黄金会员'
      }, {
        value: 2,
        label: '白金会员'
      }],
      pvipPublic:[{
        value: 0,
        label: '否'
      }, {
        value: 1,
        label: '是'
      }],
      checkList:[],
      checkDetailList:[],//查看详情标记是否选择
      labelName:'',//标签名称
      labelId:'',
      type:'',
      openTime:'',
      Level:'',
      pPublic:'',
      roleLevel:'',
      isshow:false,
      timer:null, //定时器名称
      percentage:0,
      importDataBtnIcon: "el-icon-upload",
      importDataDisabled: false,
    };
  },
  created() {
    this.getUserInfo();
    this.getLabelList(this.labelForm.labelName);
    // this.labelTime();
  },
  beforeDestroy(){
    clearInterval(this.timer);// 清除定时器
    this.timer = null;
  },
  methods: {
    getUserInfo() {
        user.userinfo().then(res => {
            if(res.data.data.result.meId==27){
              this.isshow=true
            }
            if(res.data.data.result.soeId){
                this.roleLevel = "门店"
            }else if(res.data.data.result.meId){
                this.roleLevel = "商户"
            }else if(res.data.data.result.alId){
                this.roleLevel = "商盟"
            }else{
                this.roleLevel = "平台"
            }
            //this.userInfo = res.data.data.result;
        });
    },
    indexMethod(index) {
      index = (index + 1) + (this.current - 1) * this.size
      return index
    },
    handleClick(tab, event) {
      if (tab.name === "first") {
        this.btnTitle = "新建商盟标签";
        this.title = "添加商盟标签";
        this.tye = "warning";
      } else {
        this.btnTitle = "新建商户标签";
        this.title = "添加商户标签";
        this.tye = "primary";
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.createDialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
      this.createDialogVisible = false;
      this.editForm = {};
    },
    search() {
      this.merVipInfo(false);
    },
    refresh() {
      this.searchForm = {};
      this.openTime = "";
      this.Level = "";
      this.merVipInfo(false);
    },
    ghSearch() {
      this.getVipUsers(this.labelId,this.labelName,true);
    },
    ghRefresh() {
      this.weldingForm = {};
      this.openTime = "";
      this.Level = "";
      this.pPublic = "";
      this.getVipUsers(this.labelId,this.labelName,true);
    },
    detailsClose() {
      this.ghRefresh();
      this.checkDetailList = [];
      this.detailsDialog = false;
      this.currentxp = 1;
    },
    addClose() {
      this.addDialog = false;
      this.checkList = [];
      this.checkedAll = false;
      this.refresh();
      this.current = 1;
    },
   
    handleSizeChange(val) {
      this.size = val;
      this.merVipInfo(false);
    },
    handleCurrentChange(val) {
      this.current = val;
      this.merVipInfo(false);
    },
    handleSizexpChange(val) {
      this.sizexp = val;
      this.getVipUsers(this.labelId,this.labelName,false);
    },
    handleCurrentxpChange(val) {
      this.currentxp = val;
      this.getVipUsers(this.labelId,this.labelName,false);
    },
    searchLabel() {
      this.getLabelList(this.labelForm.labelName);
    },
    showHighSerch() {
      this.nohigh = !this.nohigh;
    },
    getLabelList() {
      labelApi
        .labelList(this.labelForm.labelName, this.activeName)
        .then((res) => {
          this.first = res.data.data.aliLabel;
          this.second = res.data.data.merLebel;
        });
    },
    selectItem(list, row) {

      const ids = this.addData.map((i) => {
        return i.pvip_id+'';
      });
      for (var i = 0; i < this.checkList.length; i++) {
        if (ids.indexOf(this.checkList[i].pvip_id+'') > -1) {
          this.checkList.splice(i, 1);
          i--;
        }
      }
      const arr = [...list, ...this.checkList];
      this.checkList = Array.from(new Set(arr));
    },
    selectAll(selection) {
      const ids = this.addData.map((i) => {
        return i.pvip_id+'';
      });
      for (var i = 0; i < this.checkList.length; i++) {
        if (ids.indexOf(this.checkList[i].pvipCards+'') > -1) {
          this.checkList.splice(i, 1);
          i--;
        }
      }

      const arr = [...selection, ...this.checkList];
      this.checkList = Array.from(new Set(arr));
    },
    unique(arr,key) {//根据某个属性去重
      let map = new Map();
      return arr.filter(item => !map.has(item[key]) && map.set(item[key],1));
    },
    toggleSelection() {
      if(this.checkedAll) {//全选了所有页
        this.$nextTick(()=>{
          this.changeCheckAll(this.checkedAll);
        })
      } else {
        this.$nextTick(() => {
          this.checkList.forEach((row1) => {
            for (let i = 0; i < this.addData.length; i++) {
              if (row1.pvip_id+'' === this.addData[i].pvip_id+'') {
                this.$refs.multipleTables.toggleRowSelection(
                        this.addData[i],
                        true
                );
              }
            }
          });
        });
      }
    },
    handleSelectionChange(array) {
      if (array.length > 0) {
        this.choiceDisabled = false;
      } else {
        this.choiceDisabled = true;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let status = this.editForm.labelId ? "update" : "save";
          labelApi
            .saveLabel(status, this.activeName, this.editForm)
            .then((res) => {
              if (
                res.data.message == "商户标签建立成功" ||
                res.data.message == "商盟标签建立成功" ||
                res.data.message == "商户标签修改成功" ||
                res.data.message == "商盟标签修改成功"
              ) {
                this.$notify({
                  title: "prefect",
                  message: res.data.message,
                  position: "bottom-right",
                  type: "success",
                });
                this.getLabelList();
              } else {
                this.$notify({
                  title: "prefect",
                  message: res.data.message,
                  position: "bottom-right",
                  type: "error",
                });
              }
              this.createDialogVisible = false;
              this.editForm = {};
            });
        } else {
          return false;
        }
      });
    },
    selectDetailItem(list, row){
      const ids = this.tableData.map((i) => {
        return i.pvip_id+'';
      });
      for (var i = 0; i < this.checkDetailList.length; i++) {
        if (ids.indexOf(this.checkDetailList[i].pvip_id+'') > -1) {
          this.checkDetailList.splice(i, 1);
          i--;
        }
      }
      const arr = [...list, ...this.checkDetailList];
      this.checkDetailList = Array.from(new Set(arr));
    },
    selectDetailAll(selection){
      const ids = this.tableData.map((i) => {
        return i.pvip_id+'';
      });
      for (var i = 0; i < this.checkDetailList.length; i++) {
        if (ids.indexOf(this.checkDetailList[i].pvip_id+'') > -1) {
          this.checkDetailList.splice(i, 1);
          i--;
        }
      }

      const arr = [...selection, ...this.checkDetailList];
      this.checkDetailList = Array.from(new Set(arr));
    },
    async getVipUsers(labelId,labelName,flag) {
      
      
      this.labelId = labelId;
      this.labelName = labelName;
      await labelApi.getMercLabelList(this.weldingForm,this.openTime,this.Level,
      this.pPublic,labelId,this.currentxp,labelName).then((res) => {
        this.tableData = res.data.data.reslut.records;
        this.totalxp = res.data.data.reslut.total
        if(flag){
          this.checkDetailList = res.data.data.reslut.alreadyList;
        }
        this.addTableLoading = false;
      });
      this.toggleDetailSelection();
    },
    toggleDetailSelection() {
      this.$nextTick(() => {
          this.checkDetailList.forEach((row1) => {
            for (let i = 0; i < this.tableData.length; i++) {
              if (row1.pvip_id+'' === this.tableData[i].pvip_id+'') {
                this.$refs.multipleTable.toggleRowSelection(
                        this.tableData[i],
                        true
                );
              }
            }
          });
        });
    },
    sendAdd(labelId, labelName, type) {
        this.labelName = labelName;
        this.labelId=labelId
        this.addDialog = true;
        this.merVipInfo(true);
    },
    //查看详情
    detailAdd(labelId, labelName){
      this.detailsDialog = true;
      this.addTableLoading = true;
      this.getVipUsers(labelId,labelName,true)
    },
    async merVipInfo(isInit) {//isInit：是否初始化加载，初始化加载时同时初始化已选数组
      this.addTableLoading = true;
      await labelApi
        .getMerVipInfo(this.searchForm, this.openTime, this.Level, this.current,this.size, this.labelName, isInit)
        .then((res) => {
          this.addData = res.data.data.result.records;
          this.size = res.data.data.result.size;
          this.total = res.data.data.result.total;
          if(isInit) {
            // let alreadyList = JSON.parse(JSON.stringify(res.data.data.result.alreadyList));
            //console.log(res.data.data.result.alreadyList);
            this.checkList = res.data.data.result.alreadyList;
          }
          this.addTableLoading = false;
        });
      this.toggleSelection();
    },
    sendGroup(labelId, laberLevel, labelName, type) {
      
      labelApi.getMercLabelAllList(labelId, type).then((res) => {
        if (res.data.data.result === 0) {
          this.$notify({
            title: "提示信息",
            message: "[" + labelName + "]" + "标签未添加会员",
            type: "error",
            position: "bottom-right",
          });
        } else {
          let item = {
            labelId: labelId,
            laberLevel: laberLevel,
            labelName: labelName,
          };        
          this.$router.push({
            path: "/vip/infoLabelModel",
            query: item,
          });
        }
      });

    },
    async onSubmit(type) {
      let searchForm = '';
      let checkList = '';
      let bqCount = '';
      if(type === "C"){
        await this.merVipInfo(false);//避免输入了查询内容，但是没点查询按钮的 情况
        bqCount = this.checkedAll ? this.total : this.checkList.length;
        if(bqCount === 0) {
          this.$message({
            message: "请至少选择一条数据!",
            type: "warning",
          });
          return;
        }
        searchForm = this.searchForm;
        checkList = this.checkList;
      }else if(type === "D"){
        bqCount = this.checkDetailList.length;
        if(bqCount === 0) {
          this.$message({
            message: "请至少选择一条数据!",
            type: "warning",
          });
          return;
        }
        searchForm = this.weldingForm;
        checkList = this.checkDetailList;
      }
      console.log(this.labelId)
      await this.$confirm("是否确认对选择的"+bqCount+"个用户打" + this.labelName + "标签？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "del-model",
        type: "warning",
      }).then(() => {
        this.addTableLoading = true;
        let params = {
          multipleSelection: checkList,
          frequency: this.labelId,
        };
        api.insertSoil(params, this.checkedAll, searchForm, this.openTime, this.Level).then((res) => {
          if (res.data.data.result) {
            this.$notify({
              title: "提示信息",
              message: "操作成功",
              type: "success",
              position: "bottom-right",
            });
            this.addDialog = false;
            this.detailsDialog = false;
            this.addTableLoading = false;
            this.getLabelList();
            this.checkList = [];
            this.checkDetailList=[];
            this.checkedAll = false;
            
          }
        });
      });
      this.refresh();
      this.ghRefresh();
      this.currentxp = 1;
      this.current = 1;
    },
    async eliminate(type){
      let searchForm = '';
      let checkList = '';
      let bqCount = '';
      if(type === "C"){
        await this.merVipInfo(false);//避免输入了查询内容，但是没点查询按钮的 情况
        bqCount = this.checkedAll ? this.total : this.checkList.length;
        if(bqCount === 0) {
          this.$message({
            message: "请至少选择一条数据!",
            type: "warning",
          });
          return;
        }
        searchForm = this.searchForm;
        checkList = this.checkList;
      }else if(type === "D"){
        bqCount = this.checkDetailList.length;
        if(bqCount === 0) {
          this.$message({
            message: "请至少选择一条数据!",
            type: "warning",
          });
          return;
        }
        searchForm = this.weldingForm;
        checkList = this.checkDetailList;
      }
      

      await this.$confirm("是否在'"+ this.labelName+"'标签剔除对选择的"+bqCount+"个用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "del-model",
        type: "warning",
      }).then(() => {
        this.addTableLoading = true;
        let params = {
          multipleSelection: checkList,
          frequency: this.labelId,
        };
        api.eliminate(params, this.checkedAll, searchForm, this.openTime, this.Level).then((res) => {
          if (res.data.data.result) {
            this.$notify({
              title: "提示信息",
              message: "操作成功",
              type: "success",
              position: "bottom-right",
            });
            this.addDialog = false;
            this.detailsDialog = false;
            this.addTableLoading = false;
            this.getLabelList();
            this.checkList = [];
            this.checkDetailList=[];
            this.checkedAll = false;
            
          }
        });
      });
      this.refresh();
      this.ghRefresh();
      this.currentxp = 1;
      this.current = 1;
    },
    deleteLabel(labelId, type, wxid, name) {
      let tagId = {
        tag: {
          id: wxid,
        },
      };
      let wid = JSON.stringify(tagId);
      this.$confirm("是否删除" + name + "标签", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "del-model",
        type: "warning",
      }).then(() => {
        labelApi.deleteLabel(labelId, type, wid).then((res) => {
          if (res.data.data.result) {
            this.$notify({
              title: "prefect",
              message: "删除成功",
              type: "success",
              position: "bottom-right",
            });
            this.getLabelList();
          }
        });
      });
    },
    refreshButton(){
      this.getLabelList();
    },
    editLabel(labelId, name, notes, type) {
      this.$set(this.editForm, "labelName", name);
      this.$set(this.editForm, "labelNotes", notes);
      this.$set(this.editForm, "labelId", labelId);
      this.$set(this.editForm, "type", type);
      this.createDialogVisible = true;
    },
    selectable(row, rowIndex) {
      return !this.checkedAll;
    },
    //改变全选所有复选框
    changeCheckAll(val) {
      if (val) {
        // 全选选中时当前页所有数据选中
        this.addData.forEach(row => {
          if (row) {
            this.$refs.multipleTables.toggleRowSelection(row, true);
          }
        });
      } else {
        this.$refs.multipleTables.clearSelection();
        this.checkList = [];
      }
    },
    labelTime(){
      this.timer = setInterval(()=>{
        this.lableCount();
      },3000)
    },
    lableCount(){
      pr.getlableCount().then(res =>{
        if(res.data.data.result !== 'start'){
          this.percentage = res.data.data.count;
          this.getLabelList();
        }else{
            this.percentage = 0;
            this.getLabelList();
          }
      });
    },
    syncLabel(){
      pr.getlableCount().then(res =>{
        if(res.data.data.result !== 'start'){
          this.$notify({
            title: "消息提示",
            message: res.data.data.result,
            position: "bottom-right",
            type: "success",
          });
        }else{
          this.$confirm('是否同步高频、低频、睡眠标签', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              this.$notify({
                title: "消息提示",
                message: "标签正在同步",
                position: "bottom-right",
                type: "success",
              });
              pr.getSyncLabel(sid).then(res =>{
                if(res.data.data.result === 'success'){
                  this.$notify({
                    title: "消息提示",
                    message: "标签同步完成",
                    position: "bottom-right",
                    type: "success",
                  });
                }else if(res.data.data.result === 'error'){
                  this.$notify({
                    title: "消息提示",
                    message: "同步失败",
                    position: "bottom-right",
                    type: "error",
                  });
                }
              });
          }).catch(() => {
                
          });
        }
      });
    },
     //导入数据
    beforeUpload(file) {
      this.importDataBtnIcon = "el-icon-loading";
      this.importDataDisabled = true;
      const extension = file.name.split(".")[1] == "xls";
      const extension2 = file.name.split(".")[1] == "xlsx";
      const extension5 = file.name.split(".")[1] == "xlsm";
      const list2M = file.size / 1024 / 1024 <= 10;
      console.log(extension, extension2, extension5);
      if (!extension && !extension2 && !extension5) {
        this.$message({
          message: "上传模板只能是xls,xlsx格式",
          type: "error",
        });
        this.importDataBtnIcon = "el-icon-upload";
        this.importDataDisabled = false;
      }
      if (!list2M) {
        this.$message({
          message: "上传模板大小不能超过10MB!",
          type: "error",
        });
        this.importDataBtnIcon = "el-icon-upload";
        this.importDataDisabled = false;
      }
      return extension || extension2 || extension5 || (extension5 && list2M);
    },
    async exportData(item) {
      console.log(item)
      const form = new FormData();
      form.append("file", item.file);
      let res = await labelApi.uploadExcel(item.action,form);
      console.log(res)
      if (res.data.code == 200) {
        this.$message({
          message: res.data.message,
          type: "success",
          onClose:(res)=>{
            this.getLabelList()
          }
        });
        this.importDataBtnIcon = "el-icon-upload";
        this.importDataDisabled = false;
      }
    },
  },
};
</script>

<style scoped>
.tjhy-dialog-table /deep/ .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "当页";
  color: #606266;
  font-weight: 500;
  margin-left: 10px;
  font-size: 14px;
}
.card-contanier {
  margin-bottom: 5px;
}
.box-card {
  width: 300px;
  height: 240px;
}
.text {
  font-size: 14px;
}
.item {
  margin: 10px 10px 18px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  align-items: left;
  height: 200px;
}
.one_text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
}
.one_text span {
  display: block;
}
</style>
<style>
.labelcreat .el-card__header {
  background-color: #409eff;
  height: 40px !important;
  line-height: 40px;
  padding: 0 5px;
}
.labelcreat .el-tab-pane {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.circle {
  border-radius: 80%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
</style>
