import { postRequest, getRequest, deleteRequest } from '../utils/request'


export default {

   /**
    * 获得商盟或者商户的标签
    * @returns
    */
    labelList(labelName,active) {
        return getRequest(`/api/label/list?labelName=${labelName}&active=${active}`)
    },
    saveLabel(status,active,params){
        return postRequest(`/api/label/${status}/${active}`,params)
    },
    deleteLabel(labelId,type,data){
        return postRequest(`/api/label/delete?labelId=${labelId}&type=${type}`,data)
    },
    getMercLabelList(params,openTime,pVipLevel,pvipPublic,merLabelId,current,labelName){
        let pvipCards = "";
        let pvipName = "";
        let pvipNickname = "";
        let pvipPhone = "";
        let openStartDate = "";
        let openEndDate = "";
        if(params.pvipCards != undefined){
            pvipCards = params.pvipCards;
        }
        if(params.pvipName != undefined){
            pvipName = params.pvipName;
        }
        if(params.pvipNickname != undefined){
            pvipNickname = params.pvipNickname;
        }
        if(params.pvipPhone != undefined){
            pvipPhone = params.pvipPhone;
        }
        if(openTime != null && openTime != ''){
            openStartDate = openTime[0]
            openEndDate = openTime[1]
        }
        return getRequest(`/api/label/getMercLabelList?merLabelId=${merLabelId}&current=${current}&pvipCards=${pvipCards}&pvipName=${pvipName}&pvipNickname=${pvipNickname}&pvipPhone=${pvipPhone}&pVipLevel=${pVipLevel}&openStartDate=${openStartDate}&openEndDate=${openEndDate}&pvipPublic=${pvipPublic}&labelName=${labelName}`)
    },
    getMercLabelAllList(merLabelId,type){
        return getRequest(`/api/label/getMercLabelAllList?merLabelId=${merLabelId}&type=${type}`)
    },
    getMerVipInfo(params,openTime,pVipLevel,current,size, labelName, isInit){
        //这里如果要修改，solidifiaction.js里的insertSoil方法也要同步修改，切记 huminghao 20221204
        let pvipCards = "";
        let pvipName = "";
        let pvipNickname = "";
        let pvipPhone = "";
        let openStartDate = "";
        let openEndDate = "";
        if(params.pvipCards != undefined){
            pvipCards = params.pvipCards;
        }
        if(params.pvipName != undefined){
            pvipName = params.pvipName;
        }
        if(params.pvipNickname != undefined){
            pvipNickname = params.pvipNickname;
        }
        if(params.pvipPhone != undefined){
            pvipPhone = params.pvipPhone;
        }
        if(openTime != null && openTime != ''){
            openStartDate = openTime[0]
            openEndDate = openTime[1]
        }
        return getRequest(`/api/label/getMerVipInfo?current=${current}&pvipCards=${pvipCards}&pvipName=${pvipName}&pvipNickname=${pvipNickname}&pvipPhone=${pvipPhone}&pVipLevel=${pVipLevel}&openStartDate=${openStartDate}&openEndDate=${openEndDate}&size=${size}&labelName=${labelName}&isInit=${isInit}`)
    },
    uploadExcel(merLabelId,file){
        return postRequest(`/api/label/uploadExcel/${merLabelId}`,file)
    }

}
