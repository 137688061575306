import { postRequest, getRequest, deleteRequest } from '../utils/request'
export default {
    getListSyPrivateVip(params,current,openTime,offcUseDm,pvipPublic,pVipLevel,pvipIsdel,size,isExport){
      let openStartDate = "sa";
      let openEndDate = "sa";
      let offcStartDate = "sa";
      let offcEndDate = "sa";
      if(openTime != null && openTime != ''){
        openStartDate = openTime[0]
        openEndDate = openTime[1]
      }
      if(offcUseDm != null && offcUseDm != ''){
        offcStartDate = offcUseDm[0]
        offcEndDate = offcUseDm[1]
      }
        return getRequest(`/vip/private/getListSyPrivateVip?current=${current}&openStartDate=${openStartDate}&openEndDate=${openEndDate}&offcStartDate=${offcStartDate}&offcEndDate=${offcEndDate}&pvPublic=${pvipPublic}&pVipLevel=${pVipLevel}&Isdel=${pvipIsdel}&size=${size}&isExport=${isExport}`,params)
    },
    getQueryBySvlId(pvipId){
        return getRequest(`/vip/private/getQueryBySvlId?pvipId=${pvipId}`)
    },
    totalPvIpCards(pVipCards){
        return postRequest(`/vip/private/totalPvIpCards?pVipCards=${pVipCards}`)
    },
    getListGiftLogs(pVipCards,current,size){
        return getRequest(`/vip/private/getListGiftLogs?pVipCards=${pVipCards}&current=${current}&size=${size}`)
    },
    getListRecordsInfos(pVipCards,current,size){
        return getRequest(`/vip/private/getListRecordsInfos?pVipCards=${pVipCards}&current=${current}&size=${size}`)
    },
    getListVipCard(){
        return getRequest(`/vip/private/getListVipCard`)
    },
    getListSyCoupons(couType){
        return getRequest(`/vip/private/getListSyCoupons?couType=${couType}`)
    },
    getInsertSendGroupLogs(data){
        return postRequest(`/vip/private/getInsertSendGroupLogs`,data)
    },
    selectStrFList(){
        return getRequest(`/sy/merclabel/selectFz`)
    },
    selectHxeGc(params){
        return postRequest(`/sy/viplabel/selectHxeGc`,params)
    },
    saveVipLab(data){
        return postRequest(`/sy/viplabel/save`,data)
    },
    getDeleteBySvlId(svlId){
        return postRequest(`/vip/private/getDeleteBySvlId?svlId=${svlId}`)
    },
    getInfoSynchro(){
        return getRequest(`/vip/private/getInfoSynchro`)
    },
    updateSyPrivate(params){
        return postRequest(`/vip/private/updateSyPrivate`,params)
    },
    getSyncLabel(){
        return getRequest(`/solidifiaction/getSyncLabel`)
    },
    getlableCount(){
        return getRequest(`/solidifiaction/getlableCount`)
    }
}
